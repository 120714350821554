<template>
  <div style="white-space: break-spaces">
    <span class="qchat-conv__bubble__message"
      >Audio Message.
      <a :href="message.file.url" target="_blank">Click to see</a>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => {}
    },
    channel: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isRead: false
    };
  }
};
</script>
